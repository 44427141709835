import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { Breadcrumb } from 'src/app/model/breadcrumb.model';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { User } from 'src/app/model/user.model';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DefaultLayoutComponent implements OnInit {
  @ViewChild('sidenav', { static: false }) sidenav: MatSidenav;
  isNavBarOpen = true;
  menu: Breadcrumb[] = [];
  currentUser: User;

  constructor(private breadcrumbService: BreadcrumbService, private router: Router, private authenticationService: AuthenticationService) {
    this.menu = this.breadcrumbService.getSideBar();
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit() {
    this.setClassNav();
  }

  setClassNav() {
    let i= 1;
    const el1 = Array.from(document.getElementsByClassName('side-active'));
    el1.forEach((item, index) => {
      var r2 = el1[index].closest("li");
      r2.classList.remove('menuitem-active');
    });
    setTimeout(() => {
      const el = document.getElementsByClassName('side-active active');
      if (el && el.length > 0) {
        var r2 = el[0].closest("li");
        r2.classList.add('menuitem-active');
      }
   }, 200);
  }

  setAcive(){
    setTimeout(() => {
    for (let index = 0; index < 5; index++) {
      this.setClassNav();
    }
  },1000);
  }

  toggleSideNav(){
    if(!this.isNavBarOpen){
      var x = document.getElementsByTagName("body")[0];
      x.setAttribute('data-leftbar-size', 'condensed');
      x.classList.add('sidebar-enable');

    }else {
      var x = document.getElementsByTagName("body")[0];
      x.setAttribute('data-leftbar-size', 'default');
      x.classList.remove('sidebar-enable');
    }
  }


  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
      // this.authenticationService.logout().subscribe(
      //   (res) => {
      //     console.log(res, 'rrr');
      //     this.router.navigate(['/login']);
      //   }, (err) => {
      //     this.router.navigate(['/login']);
      //   }
      // )
}

}
