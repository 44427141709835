<div *ngIf="data.id=='create-user-modal'">
    <button mat-icon-button class="close-button" mat-dialog-close>
      <mat-icon class="close-icon">close</mat-icon>
    </button>
    <h2 mat-dialog-title>{{data.title}}</h2>
    <mat-dialog-content class="user-modal">
      <div>
        <form [formGroup]="userFormGroup">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>First Name <span class="text-danger">*</span></label>
                <input type="text" class="form-control" placeholder="Enter First Name" formControlName="firstName"
                [ngClass]="(userFormInvalid && userFormGroup?.get('firstName')?.hasError('required'))?'invalid-fields':''">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Last Name <span class="text-danger">*</span></label>
                <input type="text" class="form-control" placeholder="Enter Last Name" formControlName="lastName"
                [ngClass]="(userFormInvalid && userFormGroup?.get('lastName')?.hasError('required'))?'invalid-fields':''">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Email <span class="text-danger">*</span></label>
                <input type="text" class="form-control" placeholder="Enter Email" formControlName="email" (input)="setUserName()" 
                [ngClass]="(userFormInvalid && (userFormGroup?.get('email')?.hasError('required') || userFormGroup?.get('email')?.hasError('email')))?'invalid-fields':''">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Password <span class="text-danger">*</span></label>
                <input type="password" class="form-control" placeholder="Enter Password" formControlName="password"
                [ngClass]="(userFormInvalid && userFormGroup?.get('password')?.hasError('required'))?'invalid-fields':''">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Job Title</label>
                <input type="text" class="form-control" placeholder="Enter Job Title" formControlName="title">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Phone Number </label>
                <input type="text" class="form-control" placeholder="Enter Phone Number" formControlName="phone"
                (keypress)="numberValidation($event)">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>AI Assistant</label>
                <select class="form-control form-select" formControlName="assistantId">
                  <option value="">Select Assistant</option>
                  <ng-container *ngFor="let element of assistantList">
                  <option  value="{{element?.assistantId}}">{{element?.firstName}} {{element?.lastName}}</option>
                  </ng-container>
                </select>              
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Time Zone</label>
                <select class="form-control form-select" formControlName="timeZone">
                  <option value="">Select Time Zone</option>
                  <option *ngFor="let item of timeZoneList" value="{{item}}">{{item}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Manager</label>
                <select class="form-control form-select" formControlName="managerId">
                  <option value="">Select Manager</option>
                  <ng-container *ngFor="let element of managerList">
                  <option  value="{{element?.id}}">{{element?.firstName}} {{element?.lastName}}</option>
                  </ng-container>
                </select>              
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <div class="form-check">
                  <label class="form-check-label">
                    <input type="checkbox" class="form-check-input" formControlName="isActive"> Active
                  </label>
              </div>
              <div class="form-check">
                <label class="form-check-label">
                  <input type="checkbox" class="form-check-input" formControlName="isAdmin"> Admin
                </label>
            </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </mat-dialog-content>
  
    <mat-dialog-actions>
      <div>
        <button type="button" class="btn btn-outline-dark waves-effect waves-light min-width-100"
          mat-dialog-close>{{data.buttons[0].name}}</button>
        <button type="button" class="btn action-btn waves-light min-width-100"
          (click)="createUser()">{{data.buttons[1].name}}</button>
      </div>
    </mat-dialog-actions>
  </div>