import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class AuthenticationService {
    constructor(http) {
        this.http = http;
        this.API_VERSION = "v1";
        this.BASE_URL = environment.apiUrls.baseUrl;
        this.currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem("currentUser")));
        this.currentUser = this.currentUserSubject.asObservable();
    }
    get currentUserValue() {
        return this.currentUserSubject.value;
    }
    signUp(userInfo) {
        const url = `${this.BASE_URL}/${this.API_VERSION}/auth/signup`;
        const body = userInfo;
        return this.http.post(url, body);
    }
    signIn(userInfo) {
        const url = `${this.BASE_URL}/${this.API_VERSION}/auth/login`;
        const body = userInfo;
        return this.http.post(url, body).pipe(map((user) => {
            // store user details and basic auth credentials in local storage to keep user logged in between page refreshes
            user.username = userInfo["userName"];
            localStorage.setItem("currentUser", JSON.stringify(user));
            this.currentUserSubject.next(user);
            return user;
        }));
    }
    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem("currentUser");
        this.currentUserSubject.next(null);
    }
}
AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.HttpClient)); }, token: AuthenticationService, providedIn: "root" });
