import { Routes } from '@angular/router';
import { AuthGuard } from 'src/app/helper/auth.guard';
import { DefaultLayoutComponent } from './default-layout.component';
const ɵ0 = () => import("./../../pages/home/home.module.ngfactory").then(m => m.HomeModuleNgFactory), ɵ1 = () => import("./../../pages/admin/admin.module.ngfactory").then(m => m.AdminModuleNgFactory), ɵ2 = () => import("./../../pages/settings/settings.module.ngfactory").then(m => m.SettingsModuleNgFactory), ɵ3 = () => import("./../../pages/inbox/inbox.module.ngfactory").then(m => m.InboxModuleNgFactory), ɵ4 = () => import("./../../pages/campagin-management/campagin-management.module.ngfactory").then(m => m.CampaginManagementModuleNgFactory), ɵ5 = () => import("./../../pages/abm-accounts/abm-accounts.module.ngfactory").then(m => m.AbmAccountsModuleNgFactory), ɵ6 = () => import("./../../pages/activity/reports.module.ngfactory").then(m => m.ReportsModuleNgFactory), ɵ7 = () => import("./../../pages/prospect/prospect.module.ngfactory").then(m => m.ProspectModuleNgFactory);
const routes = [{
        path: '',
        component: DefaultLayoutComponent,
        children: [
            {
                path: 'home', canActivate: [AuthGuard],
                loadChildren: ɵ0
            },
            {
                path: 'user', canActivate: [AuthGuard],
                loadChildren: ɵ1
            },
            {
                path: 'settings', canActivate: [AuthGuard],
                loadChildren: ɵ2
            },
            {
                path: 'inbox', canActivate: [AuthGuard],
                loadChildren: ɵ3
            },
            {
                path: 'campaign-management', canActivate: [AuthGuard],
                loadChildren: ɵ4
            },
            {
                path: 'abm-accounts', canActivate: [AuthGuard],
                loadChildren: ɵ5
            },
            {
                path: 'reports', canActivate: [AuthGuard],
                loadChildren: ɵ6
            },
            {
                path: 'prospect', canActivate: [AuthGuard],
                loadChildren: ɵ7
            },
            {
                path: '**',
                redirectTo: '/sign-in',
                pathMatch: 'full'
            }
        ]
    }];
export class DefaultLayoutRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7 };
