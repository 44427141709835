import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/helper/auth.guard';
import { DefaultLayoutComponent } from './default-layout.component';

const routes: Routes = [{
  path: '',
  component: DefaultLayoutComponent,
  children: [
    {
      path: 'home', canActivate: [AuthGuard],
      loadChildren: () => import('../../pages/home/home.module').then(m => m.HomeModule)
    },
    {
      path: 'user', canActivate: [AuthGuard],
      loadChildren: () => import('../../pages/admin/admin.module').then(m => m.AdminModule)
    },
    {
      path: 'settings', canActivate: [AuthGuard],
      loadChildren: () => import('../../pages/settings/settings.module').then(m => m.SettingsModule)
    },
    {
      path: 'inbox', canActivate: [AuthGuard],
      loadChildren: () => import('../../pages/inbox/inbox.module').then(m => m.InboxModule)
    },
    {
      path: 'campaign-management', canActivate: [AuthGuard],
      loadChildren: () => import('../../pages/campagin-management/campagin-management.module').then(m => m.CampaginManagementModule)
    },
    {
      path: 'abm-accounts', canActivate: [AuthGuard],
      loadChildren: () => import('../../pages/abm-accounts/abm-accounts.module').then(m => m.AbmAccountsModule)
    },
    {
      path: 'reports', canActivate: [AuthGuard],
      loadChildren: () => import('../../pages/activity/reports.module').then(m => m.ReportsModule)
    },
    
    {
      path: 'prospect', canActivate: [AuthGuard],
      loadChildren: () => import('../../pages/prospect/prospect.module').then(m => m.ProspectModule)
    },
    {
      path: '**',
      redirectTo: '/sign-in',
      pathMatch: 'full'
    }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DefaultLayoutRoutingModule { }
