<div *ngIf="data.id=='asset-data-modal'">
    <button mat-icon-button class="close-button" mat-dialog-close>
      <mat-icon class="close-icon">close</mat-icon>
    </button>
    <h2 mat-dialog-title>{{data.title}}</h2>
    <mat-dialog-content class="prospect-modal">
      <div>
        <form [formGroup]="assetFormGroup">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Asset Name <span class="text-danger">*</span></label>
                <input type="text" class="form-control" placeholder="Enter Name" formControlName="name"
                  [ngClass]="(assetFormInvalid && assetFormGroup?.get('name')?.hasError('required'))?'invalid-fields':''">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Asset Type</label>
              <select class="form-control form-select" formControlName="type" (change)="checkScore()"
              [ngClass]="(assetFormGroup?.get('type')?.hasError('incorrect'))?'invalid-fields':''">
                <option value="">Select Asset Type</option>
                <option *ngFor="let element of assetType" value="{{element}}">{{element}}</option>
              </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Asset Link</label>
                <input type="text" class="form-control" placeholder="Enter Asset Link" formControlName="url"
                  [ngClass]="(assetFormInvalid && assetFormGroup?.get('url')?.hasError('required'))?'invalid-fields':''">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Asset Score </label>
                <input type="text" class="form-control" placeholder="Enter Asset Score" formControlName="score"
                (keypress)="numberValidation($event)" (input)="checkScore()"
                [ngClass]="(assetFormGroup?.get('score')?.hasError('incorrect'))?'invalid-fields':''">
                <div class="err-msg">{{this.assetMsg}}</div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </mat-dialog-content>
  
    <mat-dialog-actions>
      <div>
        <button type="button" class="btn btn-outline-dark waves-effect waves-light min-width-100"
          mat-dialog-close>{{data.buttons[0].name}}</button>
        <button type="button" class="btn action-btn waves-light min-width-100"
          (click)="createAsset()">{{data.buttons[1].name}}</button>
      </div>
    </mat-dialog-actions>
  </div>