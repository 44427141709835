import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';

@Component({
  selector: 'app-password-strength-bar',
  templateUrl: './password-strength-bar.component.html',
  styleUrls: ['./password-strength-bar.component.scss']
})
export class PasswordStrengthBarComponent implements OnChanges {
  //errorMessage: string | boolean;
  @Output() errorMessage = new EventEmitter();

  constructor() { }

  @Input() passwordToCheck: string;
  bar0: string;
  bar1: string;
  bar2: string;
  bar3: string;
  bar4: string;
  private colors = [ '#8b0000','#F90','#ffa500','#9acd32','#008000']
  // private colors = ['#F00', '#F90', '#FF0', '#9F0', '#0F0'];
  // private static measureStrength(pass: string) {
  //   let score = 0;
  //   // award every unique letter until 5 repetitions  
  //   let letters = {};
  //   for (let i = 0; i < pass.length; i++) {
  //     letters[pass[i]] = (letters[pass[i]] || 0) + 1;
  //     score += 5.0 / letters[pass[i]];
  //   }
  //   // bonus points for mixing it up  
  //   // let variations = {
  //   //   digits: /\d/.test(pass),
  //   //   lower: /[a-z]/.test(pass),
  //   //   upper: /[A-Z]/.test(pass),
  //   //   nonWords: /\W/.test(pass),
  //   // };
  //   let variations = {
  //     wordLength:/^(.{8,20}$)/.test(pass),
  //     digits: /^(?=.*[0-9])/.test(pass),
  //     lower: /^(?=.*[a-z])/.test(pass),
  //     upper: /^(?=.*[A-Z])/.test(pass),
  //     nonWords: /^(?=.*[@#$%&])/.test(pass),
  //   };
  //   let variationCount = 0;
  //   for (let check in variations) {
  //     variationCount += (variations[check]) ? 1 : 0;
  //   }
  //   score += (variationCount - 1) * 10;
  //   return Math.trunc(score);
  // }
  private getColor(password) {
    var idx = 0;
    if (password.length == 1) {
      idx += 0;
 }else{
    if ((/^(?=.*[A-Z])/.test(password))) {
         idx += 1;
    }
   if ((/^(?=.*[a-z])/.test(password))) {
        idx += 1;
    }
    if ((/^(?=.*[0-9])/.test(password))) {
         idx += 1;
    }
    if ((/^(?=.*[@#$%&])/.test(password)) && password.length>=8) {
         idx += 1;
    }
  }
    return {
      idx: idx + 1,
      col: this.colors[idx]
    };
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    var password = changes['passwordToCheck'].currentValue;
    this.setBarColors(5, '#DDD');
    if (password) {
      let c = this.getColor(password);
      this.setBarColors(c.idx, c.col);
      this.errorMessage.emit(this.validationMessage(password));
    }
  }

  validationMessage (password) {
    
   if (!(/^(.{8,20}$)/.test(password))) {
      return 'Password must be between 8 to 20 characters long.';
  }
    else if (!(/^(?=.*[A-Z])/.test(password))) {
        return 'Password must contain at least one uppercase.';
    }
    else if (!(/^(?=.*[a-z])/.test(password))) {
        return 'Password must contain at least one lowercase.';
    }
    else if (!(/^(?=.*[0-9])/.test(password))) {
        return 'Password must contain at least one digit.';
    }
    else if (!(/^(?=.*[@#$%&])/.test(password))) {
        return "Password must contain special characters from @#$%&.";
    }else if (!(/^(.{8,20}$)/.test(password))) {
        return 'Password must be between 8 to 20 characters long.';
    }
    return false;
}
  private setBarColors(count, col) {
    for (let _n = 0; _n < count; _n++) {
      this['bar' + _n] = col;
    }
  }

}
