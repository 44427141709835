<!-- Create Account -->
<div>
  <button mat-icon-button class="close-button" mat-dialog-close>
    <mat-icon class="close-icon">close</mat-icon>
  </button>
  <h2 mat-dialog-title>{{data.title}}</h2>
  <mat-dialog-content class="account-modal">
    <div>
      <form [formGroup]="accountFormGroup">
        <div class="row">
          <div class="col-md-6">
              <div class="form-group">
                  <label>Account Name<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" placeholder="Enter Account Name" formControlName="name"
                  [ngClass]="(accountFormInvalid && accountFormGroup?.get('name')?.hasError('required'))?'invalid-fields':''">
              </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
                <label>Website<span class="text-danger">*</span></label>
                <input type="text" class="form-control" placeholder="Enter Website" formControlName="website"
                [ngClass]="(accountFormInvalid && accountFormGroup?.get('website')?.hasError('required'))?'invalid-fields':''">
            </div>
          </div>
      </div>
      <div class="row">
          <div class="col-md-6">
              <div class="form-group">
                <label>Type<span class="text-danger">*</span></label>
                  <select class="form-control form-select" formControlName="type"
                  [ngClass]="(accountFormInvalid && accountFormGroup?.get('type')?.hasError('required'))?'invalid-fields':''">
                      <option value="">Select Type</option>
                      <option *ngFor="let type of data?.requireList?.accountTypes" value="{{type}}">{{type}}</option>
                  </select>
              </div>
          </div>
          <div class="col-md-6">
              <div class="form-group">
                <label>Account Group</label>
                <select class="form-control form-select" formControlName="groupId">
                    <option value="">Select Group</option>
                    <option *ngFor="let group of data?.requireList?.accountGroups" value="{{group}}">{{group}}</option>
                </select>
              </div>
          </div>
      </div>
      <div class="row">
          <div class="col-md-6">
            <div class="form-group">
                <label>Ownership</label>
                <select class="form-control form-select" formControlName="ownership">
                    <option value="">Select Ownership</option>
                    <option *ngFor="let owner of data?.requireList?.ownerships" value="{{owner}}">{{owner}}</option>
                </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
                <label>Industry</label>
                <select class="form-control form-select" formControlName="industry">
                    <option value="">Select Industry</option>
                    <option *ngFor="let industry of data?.requireList?.industries" value="{{industry.name}}">{{industry.name}}</option>
                </select>
            </div>
          </div>
      </div>
      </form>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div>
      <button type="button" class="btn btn-outline-dark waves-effect waves-light min-width-100"
        mat-dialog-close>{{data.buttons[0].name}}</button>
      <button type="button" class="btn action-btn waves-light min-width-100"
        (click)="createAccount()">{{data.buttons[1].name}}</button>
    </div>
  </mat-dialog-actions>
</div>