<div class="mail-compose">
    <button mat-icon-button class="close-button" mat-dialog-close>
        <mat-icon class="close-icon">close</mat-icon>
    </button>
    <h2 mat-dialog-title>{{data.title}}</h2>
    <mat-dialog-content class="user-modal">
        <form id="composeform" [formGroup]="emailFormGroup">
            <div class="form-group hidden">
                <input type="text" class="form-control" id="from" tabindex="2" placeholder="From:" formControlName="fromEmail">
            </div>
            <div class="form-group to-field">
                <input type="text" class="form-control" id="to" tabindex="1" placeholder="To:" formControlName="toEmail"
                [ngClass]="(emailFormInvalid && emailFormGroup?.get('toEmail')?.hasError('required'))?'invalid-fields':''">
                <div class="field-options">
                    <a href="javascript:;" id="cc-on" (click)="addCC()">CC</a>
                </div>
            </div>
            <div class="form-group" *ngIf="show_cc">
                <input type="text" class="form-control" id="cc" tabindex="2" placeholder="CC:" formControlName="ccEmailList">
            </div>
            <div class="form-group">
                <input type="text" class="form-control" id="subject" tabindex="1" placeholder="Subject" formControlName="subject">
            </div>
            <div class="form-group">
                <select class="form-control form-select" formControlName="assistantId"
                [ngClass]="(emailFormInvalid && emailFormGroup?.get('assistantId')?.hasError('required'))?'invalid-fields':''">
                  <option value="">Select Assistant</option>
                  <option *ngFor="let item of data.assistantData" value="{{item?.id}}">{{item?.firstName}} {{item?.lastName}}</option>
                </select>
              </div>
            <div>
                <div id="myckeditor" #myckeditor></div>
            </div>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div>
            <button type="button" class="btn btn-outline-dark waves-effect waves-light min-width-100"
                mat-dialog-close>{{data.buttons[0].name}}</button>
            <button type="button" class="btn action-btn waves-light min-width-100"
                (click)="sendMail()">{{data.buttons[1].name}}</button>
        </div>
    </mat-dialog-actions>
</div>