import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit {
  accountFormGroup = this.fb.group({
    id: [0],
    name: ['', Validators.required],
    website: ['', Validators.required],
    groupId:[''],
    type: ['', Validators.required],
    ownership:[''],
    industry:['']
  });
  accountFormInvalid = false;

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<CreateAccountComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any){
      if (data.formData) {
        this.accountFormGroup.patchValue(data.formData);
      }
  }

  ngOnInit() {
  }

  
  createAccount() {
    if (this.accountFormGroup.invalid) {
      this.accountFormInvalid = true;
    } else {
      this.accountFormInvalid = false;
      const accountDetail = this.getChanges(this.accountFormGroup);
      if (accountDetail) {
        if (this.accountFormGroup.get('id').value) {
          accountDetail['id'] = this.accountFormGroup.get('id').value;
        }
        this.dialogRef.close(accountDetail);
      }
    }
  }

  
  private getChanges(form): any {
    const updatedValues: any = {};
    this.getUpdates(form, updatedValues);
    return updatedValues;
  }

  private getUpdates(
    formItem: FormGroup | FormArray | FormControl,
    updatedValues: any,
    name?: string
  ) {
    if (formItem instanceof FormControl) {
      if (name && formItem.dirty) {
        updatedValues[name] = formItem.value;
      }
    } else {
      for (const formControlName in formItem.controls) {
        if (formItem.controls.hasOwnProperty(formControlName)) {
          const formControl = formItem.controls[formControlName];

          if (formControl instanceof FormControl) {
            this.getUpdates(formControl, updatedValues, formControlName);
          } else if (
            formControl instanceof FormArray &&
            formControl.dirty &&
            formControl.controls.length > 0
          ) {
            updatedValues[formControlName] = [];
            this.getUpdates(formControl, updatedValues[formControlName]);
          } else if (formControl instanceof FormGroup && formControl.dirty) {
            updatedValues[formControlName] = {};
            this.getUpdates(formControl, updatedValues[formControlName]);
          }
        }
      }
    }
  }

}
