<div *ngIf="data.id=='prospect-data-modal'">
  <button mat-icon-button class="close-button" mat-dialog-close>
    <mat-icon class="close-icon">close</mat-icon>
  </button>
  <h2 mat-dialog-title>{{data.title}}</h2>
  <mat-dialog-content class="prospect-modal">
    <div>
      <form [formGroup]="leadFormGroup">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>First Name <span class="text-danger">*</span></label>
              <input type="text" class="form-control" placeholder="Enter First Name" formControlName="firstName"
                [ngClass]="(leadFormInvalid && leadFormGroup?.get('firstName')?.hasError('required'))?'invalid-fields':''">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Last Name </label>
              <input type="text" class="form-control" placeholder="Enter Last Name" formControlName="lastName">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Email <span class="text-danger">*</span></label>
              <input type="text" class="form-control" placeholder="Enter Email" formControlName="email"
                [ngClass]="(leadFormInvalid && leadFormGroup?.get('email')?.hasError('required'))?'invalid-fields':''">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Phone Number </label>
              <input type="text" class="form-control" placeholder="Enter Phone Number" formControlName="phone">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Title</label>
              <input type="text" class="form-control" placeholder="Enter Title" formControlName="title">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>LinkedIn </label>
              <input type="text" class="form-control" placeholder="Enter LinkedIn Id" formControlName="linkedin">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Account Name<span class="text-danger">*</span></label>
              <select class="form-control form-select" formControlName="accountId" 
              [ngClass]="(leadFormInvalid && leadFormGroup?.get('accountId')?.hasError('required'))?'invalid-fields':''">
                <option value="">Select Account</option>
                <option *ngFor="let element of accountList" value="{{element.id}}">{{element.name}}</option>
              </select>
              <div class="error-message" *ngIf="accountError">Please create account in ABM.</div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Lead Status</label>
              <select class="form-control form-select" formControlName="leadStatus">
                <option value="">Select Lead Status</option>
                <option *ngFor="let element of leadStatusList" value="{{element}}">{{element}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Company Name </label>
              <input type="text" class="form-control" placeholder="Enter Company Name" formControlName="company">
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Description</label>
              <textarea class="form-control" formControlName="details" rows="4"></textarea>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div>
      <button type="button" class="btn btn-outline-dark waves-effect waves-light min-width-100"
        mat-dialog-close>{{data.buttons[0].name}}</button>
      <button type="button" class="btn action-btn waves-light min-width-100"
        (click)="createLead()">{{data.buttons[1].name}}</button>
    </div>
  </mat-dialog-actions>
</div>

<div *ngIf="data.id=='prospect-data-add-modal'">
  <button mat-icon-button class="close-button" mat-dialog-close>
    <mat-icon class="close-icon">close</mat-icon>
  </button>
  <h2 mat-dialog-title>{{data.title}}</h2>
  <mat-dialog-content class="prospect-modal">
    <div>
      <form>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Select List</label>
              <select class="form-control form-select" (change)="selectList($event.target.value)">
                <option>Select List form list</option>
                <option *ngFor="let element of data?.listData" value="{{element.id}}">{{element.name}}</option>
              </select>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div>
      <button type="button" class="btn btn-outline-dark waves-effect waves-light min-width-100"
        mat-dialog-close>{{data.buttons[0].name}}</button>
      <button type="button" class="btn action-btn waves-light min-width-100"
        (click)="addToList()">{{data.buttons[1].name}}</button>
    </div>
  </mat-dialog-actions>
</div>

<div *ngIf="data.id=='prospect-list-modal'">
  <button mat-icon-button class="close-button" mat-dialog-close>
    <mat-icon class="close-icon">close</mat-icon>
  </button>
  <h2 mat-dialog-title>{{data.title}}</h2>
  <mat-dialog-content class="prospect-modal">
    <div>
      <form [formGroup]="listFormGroup">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>List Name <span class="text-danger">*</span></label>
              <input type="text" class="form-control" placeholder="Enter List Name" formControlName="name"
                [ngClass]="(listFormInvalid && listFormGroup?.get('name')?.hasError('required'))?'invalid-fields':''">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Description <span class="text-danger">*</span></label>
              <textarea class="form-control" formControlName="description" rows="4"
                [ngClass]="(listFormInvalid && listFormGroup?.get('description')?.hasError('required'))?'invalid-fields':''"></textarea>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Type</label>
              <select class="form-control form-select" formControlName="type">
                <option *ngFor="let element of listTypeList" value="{{element}}">{{element}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mt-4 d-flex">
              <input type="checkbox" class="form-check-input me-2" id="customCheck1" formControlName="accessList">
              <label class="form-check-label" for="customCheck1">Enable to a public list visible to everyone</label>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div>
      <button type="button" class="btn btn-outline-dark waves-effect waves-light min-width-100"
        mat-dialog-close>{{data.buttons[0].name}}</button>
      <button type="button" class="btn action-btn waves-light min-width-100"
        (click)="createList()">{{data.buttons[1].name}}</button>
    </div>
  </mat-dialog-actions>
</div>

<div *ngIf="data.id=='prospect-lead-view-modal'">
  <button mat-icon-button class="close-button" mat-dialog-close>
    <mat-icon class="close-icon">close</mat-icon>
  </button>
  <h2 mat-dialog-title>{{data.title}}</h2>
  <mat-dialog-content class="prospect-modal">
    <div>
      <table data-toggle="table" data-page-size="8" data-buttons-class="xs btn-light" data-pagination="true"
                class="">
                <thead class="">
                  <tr>
                    <th>
                      <div class="form-check"> <label class="form-check-label"> <input type="checkbox"
                        class="form-check-input" [checked]="(data.currentLeadList.length === selectedRecord.length)"
                        [indeterminate]="(data.currentLeadList.length > selectedRecord.length && (selectedRecord.length !==0))"
                        (change)="toggleCheckboxAll($event, data.currentLeadList)"> <i class="input-helper"></i></label></div>
                    </th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Title</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let lead of data.currentLeadList;let i=index;">
                    <td>
                      <div class="form-check"> <label class="form-check-label"> <input type="checkbox"
                        class="form-check-input" [checked]="lead?.selected" (change)="singleCheckbox($event,lead)"> <i class="input-helper"></i></label></div>
                    </td>
                    <td>{{lead?.firstName}} {{lead?.lastName}}</td>
                    <td>{{lead?.email}}</td>
                    <td>{{lead?.title}}</td>
                  </tr>
                </tbody>
              </table>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div>
      <button type="button" class="btn btn-outline-dark waves-effect waves-light min-width-100"
        mat-dialog-close>{{data.buttons[0].name}}</button>
      <button type="button" class="btn action-btn waves-light min-width-100" [disabled]="selectedRecord.length === 0"
        (click)="deleteList()">{{data.buttons[1].name}}</button>
    </div>
  </mat-dialog-actions>
</div>

<div *ngIf="data.id=='account-view-modal'">
  <button mat-icon-button class="close-button" mat-dialog-close>
    <mat-icon class="close-icon">close</mat-icon>
  </button>
  <h2 mat-dialog-title>{{data.title}}</h2>
  <mat-dialog-content class="prospect-modal">
    <div>
      <table data-toggle="table" data-page-size="8" data-buttons-class="xs btn-light" data-pagination="true"
                class="">
                <thead class="">
                  <tr>
                    <th></th>
                    <th>Website</th>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Country</th>
                    <th>Ownership</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let account of data?.accountList;let i=index;">
                    <td></td>
                    <td>{{account.website}}</td>
                    <td>{{account?.name}}</td>
                    <td>{{account?.type}}</td>
                    <td>{{account?.country}}</td>
                    <td>{{account?.ownership}}</td>
                  </tr>
                </tbody>
              </table>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div>
      <button type="button" class="btn action-btn waves-light min-width-100"
        mat-dialog-close>{{data.buttons[0].name}}</button>
    </div>
  </mat-dialog-actions>
</div>

<div *ngIf="data.id=='persona-lead-view-modal'">
  <button mat-icon-button class="close-button" mat-dialog-close>
    <mat-icon class="close-icon">close</mat-icon>
  </button>
  <h2 mat-dialog-title>{{data.title}}</h2>
  <mat-dialog-content class="prospect-modal">
    <div>
      <table data-toggle="table" data-page-size="8" data-buttons-class="xs btn-light" data-pagination="true"
                class="">
                <thead class="">
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Title</th>
                    <th>Score</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let lead of data.leadList;let i=index;">
                    <td></td>
                    <td>{{lead?.firstName}} {{lead?.lastName}}</td>
                    <td>{{lead?.email}}</td>
                    <td>{{lead?.title}}</td>
                    <td>{{lead?.engScore}}</td>
                    <td>{{lead?.leadStatus}}</td>
                  </tr>
                </tbody>
              </table>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div>
      <button type="button" class="btn action-btn waves-light min-width-100" 
        mat-dialog-close>{{data.buttons[0].name}}</button>
    </div>
  </mat-dialog-actions>
</div>