import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-create-users',
  templateUrl: './create-users.component.html',
  styleUrls: ['./create-users.component.scss']
})
export class CreateUsersComponent implements OnInit{
  userFormGroup = this.fb.group({
    id: [0],
    userName: [''],
    password: ['', Validators.required],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: new FormControl('', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
    title: [''],
    timeZone: [''],
    phone: [''],
    managerId: [''],
    assistantId:[''],
    isActive: [true],
    isAdmin: [false]      
  });
  timeZoneList: string[];
  assistantList: object[];
  userFormInvalid = false;
  managerList: string[];
  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<CreateUsersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.timeZoneList = data.timeZoneList;
    this.managerList = data.managerList;
    this.assistantList = data.assistantList;
    if (data.formData) {
      this.userFormGroup.patchValue(data.formData);
    } else {
      this.userFormGroup.reset();
      this.userFormGroup.get('isActive').setValue(true);
    }
  }

  
  ngOnInit() {
    this.userFormGroup.get('isAdmin').markAsDirty();
    this.userFormGroup.get('isActive').markAsDirty();
  }

  setUserName(){
    this.userFormGroup.get('userName').setValue(this.userFormGroup.get('email').value);
    this.userFormGroup.get('userName').markAsDirty();
  }


  createUser() {
    if (this.userFormGroup.invalid) {
      this.userFormInvalid = true;
    } else {
      this.userFormInvalid = false;
      const userDetail = this.getChanges(this.userFormGroup);
      if (userDetail) {
        if (this.userFormGroup.get('id').value) {
          userDetail['id'] = this.userFormGroup.get('id').value;
        }
        this.dialogRef.close(userDetail);
      }
    }
  }

  
  numberValidation(e) {
    if (e.key.match(/^[0-9,]+$/)) {
      if (e.target.value.toString().indexOf('.') !== -1) {
        const count = e.target.value.toString().split('.')[1].length || 0;
        if (count > 5) {
          return false;
        }
      }
      return true;
    }
    return false;
  }


  private getChanges(form): any {
    const updatedValues: any = {};
    this.getUpdates(form, updatedValues);
    return updatedValues;
  }

  private getUpdates(
    formItem: FormGroup | FormArray | FormControl, updatedValues: any, name?: string) {
    if (formItem instanceof FormControl) {
      if (name && formItem.dirty) {
        updatedValues[name] = formItem.value;
      }
    } else {
      for (const formControlName in formItem.controls) {
        if (formItem.controls.hasOwnProperty(formControlName)) {
          const formControl = formItem.controls[formControlName];

          if (formControl instanceof FormControl) {
            this.getUpdates(formControl, updatedValues, formControlName);
          } else if (
            formControl instanceof FormArray &&
            formControl.dirty &&
            formControl.controls.length > 0
          ) {
            updatedValues[formControlName] = [];
            this.getUpdates(formControl, updatedValues[formControlName]);
          } else if (formControl instanceof FormGroup && formControl.dirty) {
            updatedValues[formControlName] = {};
            this.getUpdates(formControl, updatedValues[formControlName]);
          }
        }
      }
    }
  }

}
