import { Breadcrumb } from '../model/breadcrumb.model';
import { Injectable } from '@angular/core';

@Injectable()
export class BreadcrumbService {

  constructor() { }

  getMenu(): Breadcrumb[] {
    const menu = [
      { displayName: 'Dashboard', path: './home' },
      {displayName:'Users', path:'./user',
      children: [
        {
          displayName: 'Users List',
          path: './user-list',
        },
        {
          displayName: 'User Profile',
          path: './user-profile',
        }]  
    },
      { displayName: 'ABM Accounts', path: './abm-accounts' },
      { displayName: 'Activity', path: './reports' },
      { displayName: 'Inbox', path: './inbox' },
      {
        displayName: 'Prospect',
        path: './prospect',
        children: [
          {
            displayName: 'Prospect List',
            path: './prospect-list',
          },
          {
            displayName: 'Prospect Data',
            path: './prospect-data',
          }]
      },

      {
        displayName: 'Settings',
        path: './settings',
        children: [
          {
            displayName: 'AI Assistant',
            path: './ai-assistant',
          },
          {
            displayName: 'Create AI Assistant',
            path: './create-ai-assistant',
          },
          {
            displayName: 'Edit AI Assistant',
            path: './edit-ai-assistant',
          },
          {
            displayName: 'Users',
            path: './users',
            children: [
              {
                displayName: 'Create New User',
                path: './create-user'
              }]

          }

        ],
      },
      {
        displayName: 'Campaign Management',
        path: './campaign-management',
        children: [
          {
            displayName: 'Campaigns',
            path: './campaigns'
          },
          {
            displayName: 'Create Campaign',
            path: './create-campaign'
          },
          {
            displayName: 'Edit Campaign',
            path: './edit-campaign'
          },
          {
            displayName: 'Content',
            iconName: '',
            path: './content'
          }
        ]
      }
    ];
    return menu;
  }

  getSideBar(): Breadcrumb[] {
    const menubar: Breadcrumb[] = [
      {
        displayName: 'Dashboard',
        iconName: 'dashboard',
        path: '/home',
      },
      {
        displayName:'Users List',
        iconName:'people', 
        path:'./user'},

      {
        displayName: 'Inbox',
        iconName: 'mail',
        path: '/inbox',
      },
      {
        displayName: 'Prospect',
        iconName: 'people',
        path: '/prospect',
      },
      {
        displayName: 'Campaign Management',
        iconName: 'library_add_check',
        children: [
          {
            displayName: 'Campaigns',
            iconName: '',
            path: '/campagin-management/campagins'
          },
          {
            displayName: 'Content',
            iconName: '',
            path: '/campagin-management/content'
          }
        ]
      },
      {
        displayName: 'ABM Accounts',
        iconName: 'dashboard',
        path: '/abm-accounts',
      },
      {
        displayName: 'Settings',
        iconName: 'brightness_7',
        children: [
          {
            displayName: 'AI Assistant',
            iconName: '',
            path: '/settings/ai-assistant'
          }
        ]
      }
    ];
    return menubar;
  }

}
