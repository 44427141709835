import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { config } from "../../environments/app.settings.config";
import { environment } from "../../environments/environment";

@Injectable()
export class ApiService {
  private readonly BASE_URL: string;
  private API_VERSION = "v1";
  constructor(private http: HttpClient) {
    this.BASE_URL = environment.apiUrls.baseUrl;
  }
  // AI Assistant Apis
  getLangTime(): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/assistants/predefined`
    );
  }

  getAssistantDetail(assistantId): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/assistants/${assistantId}`
    );
  }

  createAssistantBasicDetail(assistant): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/assistants`;
    const body = assistant;
    return this.http.post(url, body);
  }

  createBusinessDetail(assistantId, business): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/assistants/${assistantId}/business-details`;
    const body = business;
    return this.http.post(url, body);
  }

  validateCredential(assistantId, type, channelDetail): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/assistants/${assistantId}/validate-${type}`;
    const body = channelDetail;
    return this.http.post(url, body);
  }

  getAllAiAssistant(pageNo, pageLimit, assistantName?) {
    if (assistantName) {
      return this.http.get(
        `${this.BASE_URL}/${this.API_VERSION}/assistants?page=${pageNo}&size=${pageLimit}&name=${assistantName}`
      );
    }
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/assistants?page=${pageNo}&size=${pageLimit}`
    );
  }

  updateAssistantBasicDetail(assistantId, assistant): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/assistants/${assistantId}`;
    const body = assistant;
    return this.http.put(url, body);
  }

  updateAssistantBusinessDetail(assistantId, assistant): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/assistants/${assistantId}/business-details`;
    const body = assistant;
    return this.http.put(url, body);
  }

  updateAssistantChannelDetails(assistantId, channelInfo): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/assistants/${assistantId}/channel-details`;
    const body = channelInfo;
    return this.http.put(url, body);
  }

  activateAssistant(assistantId, status): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/assistants/${assistantId}/updateStatus?status=${status}`;
    const body = null;
    return this.http.put(url, body);
  }
  // Inbox API
  unreadEmailCount(): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/email/inbox/count`
    );
  }

  getAllEmail(pageNo, pageLimit, requestType): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/email/inbox?page=${pageNo}&size=${pageLimit}&requestType=${requestType}`
    );
  }

  actionEmail(activityId, status): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/email/inbox/update?activityId=${activityId}&status=${status}`;
    const body = null;
    return this.http.put(url, body);
  }
  // Campaign Apis
  getAllAccountFunnel(pageNo, pageLimit, funnelName?): Observable<any> {
    if (funnelName) {
      return this.http.get(
        `${this.BASE_URL}/${this.API_VERSION}/campaign/account-funnels?page=${pageNo}&size=${pageLimit}&name=${funnelName}`
      );
    }
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/campaign/account-funnels?page=${pageNo}&size=${pageLimit}`
    );
  }

  deleteAccountFunnel(accountFunnelId): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/campaign/account-funnels/${accountFunnelId}/delete`;
    const body = null;
    return this.http.put(url, body);
  }

  getCountryEmp(): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/campaign/pre-requisites`
    );
  }

  createAccount(basicDetail): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/campaign/account-funnels`;
    const body = basicDetail;
    return this.http.post(url, body);
  }

  getAccountFunnel(accountFunnelId): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/campaign/account-funnels/${accountFunnelId}`
    );
  }

  updateAccountBasicDetail(accountFunnelId, account): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/campaign/account-funnels/${accountFunnelId}`;
    const body = account;
    return this.http.put(url, body);
  }
  getAllPersona(accountFunnelId): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/campaign/account-funnels/${accountFunnelId}/personas`
    );
  }

  uploadAccount(accountFunnelId, fileDetail): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/campaign/account-funnels/${accountFunnelId}/upload-accounts`;
    const body = fileDetail;
    return this.http.post(url, body);
  }

  getAccountsByFunnelId(accountFunnelId, pageNo, pageLimit): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/accounts/getAccountsByFunnelId/${accountFunnelId}?page=${pageNo}&size=${pageLimit}`
    );
  }

  getLeadsByPersonaId(personaId, pageNo, pageLimit) {
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/leads/getLeadsByPersonaId/${personaId}?page=${pageNo}&size=${pageLimit}`
    );
  }

  // Create persona
  createPersona(basicDetail, accountFunnelId): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/campaign/account-funnels/${accountFunnelId}/personas`;
    const body = basicDetail;
    return this.http.post(url, body);
  }

  getPersona(accountFunnelId, personaId): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/campaign/account-funnels/${accountFunnelId}/personas/${personaId}`
    );
  }

  updatePersona(personaDetail, accountFunnelId, personaId): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/campaign/account-funnels/${accountFunnelId}/personas/${personaId}`;
    const body = personaDetail;
    return this.http.put(url, body);
  }

  uploadLead(accountFunnelId, personaId, fileDetail): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/campaign/account-funnels/${accountFunnelId}/personas/${personaId}/upload-leads`;
    const body = fileDetail;
    return this.http.post(url, body);
  }

  getCampaignAction(): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/campaign/actions`
    );
  }

  getCampaignSequence(accountFunnelId, personaId): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/campaign/account-funnels/${accountFunnelId}/personas/${personaId}/sequences`
    );
  }

  createCampaignSequence(accountFunnelId, personaId, data): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/campaign/account-funnels/${accountFunnelId}/personas/${personaId}/sequences`;
    const body = data;
    return this.http.post(url, body);
  }

  updateCampaignSequence(accountFunnelId, personaId, data): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/campaign/account-funnels/${accountFunnelId}/personas/${personaId}/sequences`;
    const body = data;
    return this.http.put(url, body);
  }

  launchCampaignActive(accountFunnelId): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/campaign/account-funnels/${accountFunnelId}/launch`;
    const body = null;
    return this.http.post(url, body);
  }

  contentGetEmailTemplate(pageNo, pageLimit, name?): Observable<any> {
    if (name) {
      return this.http.get(
        `${this.BASE_URL}/${this.API_VERSION}/email/templates?page=${pageNo}&size=${pageLimit}&name=${name}`
      );
    }
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/email/templates?page=${pageNo}&size=${pageLimit}`
    );
  }

  getTemplateRequisites(): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/email/templates/pre-requisites`
    );
  }

  createTemplate(templateDetail): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/email/templates`;
    const body = templateDetail;
    return this.http.post(url, body);
  }
  updateTemplate(templateId, templateDetail): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/email/templates/${templateId}`;
    const body = templateDetail;
    return this.http.put(url, body);
  }

  deleteTemplate(templateIdList): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/email/templates/delete`;
    const body = templateIdList;
    return this.http.put(url, body);
  }

  uploadEmailTemplateFile(templateId, files) {
    const url = `${this.BASE_URL}/${this.API_VERSION}/email/templates/${templateId}/upload/attachments`;
    const body = files;
    return this.http.post(url, body);
  }

  getUploadedAttachments(templateId): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/email/templates/${templateId}/attachments`
    );
  }

  downloadAttachments(templateId, attachmentId): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/email/templates/${templateId}/attachments/${attachmentId}/download`,
      { responseType: "blob" }
    );
  }

  deleteAttachments(templateId, attachmentIdList): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/email/templates/${templateId}/attachments/delete`;
    const body = attachmentIdList;
    return this.http.put(url, body);
  }

  // Prospect lead
  getProspectLeadList(pageNo, pageLimit, status, name?): Observable<any> {
    if (name) {
      return this.http.get(
        `${this.BASE_URL}/${this.API_VERSION}/leads?page=${pageNo}&size=${pageLimit}&name=${name}&status=${status}`
      );
    }
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/leads?page=${pageNo}&size=${pageLimit}&status=${status}`
    );
  }

  getAllAccount(): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/accounts/getAccounts`
    );
  }

  getLeadPrerequisites(): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/leads/pre-requisites`
    );
  }

  updateProspectLead(leadId, leadDetail): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/leads/${leadId}`;
    const body = leadDetail;
    return this.http.put(url, body);
  }

  createProspectLead(leadDetail): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/leads`;
    const body = leadDetail;
    return this.http.post(url, body);
  }

  deleteProspectLead(leadIdList): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/leads/delete`;
    const body = leadIdList;
    return this.http.put(url, body);
  }

  getLeadCount(): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/leads/statusCount`
    );
  }

  getLeadActivity(leadId, pageNo, pageLimit): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/leads/${leadId}/activities?page=${pageNo}&size=${pageLimit}`
    );
  }

  getLeadEmailHistory(leadId, pageNo, pageLimit): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/leads/${leadId}/email-history?page=${pageNo}&size=${pageLimit}`
    );
  }

  uploadProspectLead(fileDetail): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/leads/upload-leads`;
    const body = fileDetail;
    return this.http.post(url, body);
  }

  addLeadList(listId, leadList): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/leads/leadList/${listId}/add`;
    const body = leadList;
    return this.http.post(url, body);
  }
  // Prospect List
  getListPrerequisites(): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/leads/leadList/pre-requisites`
    );
  }

  getProspectList(pageNo, pageLimit, name?): Observable<any> {
    if (name) {
      return this.http.get(
        `${this.BASE_URL}/${this.API_VERSION}/leads/leadList?page=${pageNo}&size=${pageLimit}&name=${name}`
      );
    }
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/leads/leadList?page=${pageNo}&size=${pageLimit}`
    );
  }

  updateProspectList(listId, listDetail): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/leads/leadList/${listId}`;
    const body = listDetail;
    return this.http.put(url, body);
  }

  createProspectList(listDetail): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/leads/leadList`;
    const body = listDetail;
    return this.http.post(url, body);
  }

  deleteProspectList(listIds): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/leads/leadList/delete`;
    const body = listIds;
    return this.http.put(url, body);
  }

  getLeadFormListId(pageNo, pageLimit, listId) {
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/leads/leadList/${listId}/leads?page=${pageNo}&size=${pageLimit}`
    );
  }

  deleteLeadList(listId, leadIdRecord) {
    const url = `${this.BASE_URL}/${this.API_VERSION}/leads/leadList/${listId}/delete`;
    const body = leadIdRecord;
    return this.http.put(url, body);
  }

  // Account

  getAccountPrerequisites(): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/accounts/pre-requisites`
    );
  }

  getAccountCount(): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/accounts/typeCount`
    );
  }

  getAccountList(pageNo, pageLimit, type, name?): Observable<any> {
    if (name) {
      return this.http.get(
        `${this.BASE_URL}/${this.API_VERSION}/accounts?page=${pageNo}&size=${pageLimit}&type=${type}&name=${name}`
      );
    }
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/accounts?page=${pageNo}&size=${pageLimit}&type=${type}`
    );
  }

  updateAccountList(accountId, accountDetail): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/accounts/${accountId}`;
    const body = accountDetail;
    return this.http.put(url, body);
  }

  createAccountList(accountDetail): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/accounts`;
    const body = accountDetail;
    return this.http.post(url, body);
  }

  deleteAccountList(listIds): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/accounts/delete`;
    const body = listIds;
    return this.http.put(url, body);
  }

  uploadAccountList(fileDetail): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/accounts/upload-accounts`;
    const body = fileDetail;
    return this.http.post(url, body);
  }

  // User Management

  createUser(userDetail): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/users/createNewUser`;
    const body = userDetail;
    return this.http.post(url, body);
  }

  updateUser(userDetail): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/users/updateUser`;
    const body = userDetail;
    return this.http.put(url, body);
  }

  deleteUser(userId): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/users/deleteUser`;
    const body = userId;
    return this.http.put(url, body);
  }

  getAllUsers(pageNo, pageLimit, name?): Observable<any> {
    if (name) {
      return this.http.get(
        `${this.BASE_URL}/${this.API_VERSION}/users/getAllUsers?page=${pageNo}&size=${pageLimit}&name=${name}`
      );
    }
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/users/getAllUsers?page=${pageNo}&size=${pageLimit}`
    );
  }

  // Asset

  getAssetPrerequisites(): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/asset/pre-requisites`
    );
  }
  createAsset(assetDetail): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/asset/create`;
    const body = assetDetail;
    return this.http.post(url, body);
  }

  updateAsset(assetDetail): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/asset/updateAsset`;
    const body = assetDetail;
    return this.http.put(url, body);
  }

  deleteAsset(assetId): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/asset/deleteAsset`;
    const body = assetId;
    return this.http.put(url, body);
  }

  getAllAssets(pageNo, pageLimit, name?): Observable<any> {
    if (name) {
      return this.http.get(
        `${this.BASE_URL}/${this.API_VERSION}/asset/getAssets?page=${pageNo}&size=${pageLimit}&name=${name}`
      );
    }
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/asset/getAssets?page=${pageNo}&size=${pageLimit}`
    );
  }

  // Admin

  getAllTenantUser(pageNo, pageLimit, type, name?): Observable<any> {
    if (name) {
      return this.http.get(
        `${this.BASE_URL}/${this.API_VERSION}/superAdmin/getTenantUsers?page=${pageNo}&size=${pageLimit}&type=${type}&name=${name}`
      );
    }
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/superAdmin/getTenantUsers?page=${pageNo}&size=${pageLimit}&type=${type}`
    );
  }

  getUserCount(): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/superAdmin/getUsersCount`
    );
  }

  updateSuperAdmin(userData): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/superAdmin/updateTenantDetails`;
    const body = userData;
    return this.http.put(url, body);
  }
  // Dashboard

  getChipData(month, year): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/dashboard/data?month=${month}&year=${year}`
    );
  }

  getProspectGraphData(month, year): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/dashboard/prospectData?month=${month}&year=${year}`
    );
  }

  getCampaignGraphData(month, year): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/dashboard/campaignData?month=${month}&year=${year}`
    );
  }

  getAssetGraphData(month, year): Observable<any> {
    return this.http.get(
      `${this.BASE_URL}/${this.API_VERSION}/dashboard/assetData?month=${month}&year=${year}`
    );
  }

  replyForwardMail(mailDetail, actionType): Observable<any> {
    const url = `${this.BASE_URL}/${this.API_VERSION}/email/reply?name=${actionType}`;
    const body = mailDetail;
    return this.http.post(url, body);
  }
}
