import { MatSnackBar } from "@angular/material/snack-bar";
import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
export class CommonService {
    constructor(snackBar) {
        this.snackBar = snackBar;
        this.currentAssistant$ = new BehaviorSubject({});
        this.currentUser$ = new BehaviorSubject({});
    }
    openSnacbar(message) {
        this.snackBar.open(message, '', { duration: 5 * 1000 });
    }
}
CommonService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CommonService_Factory() { return new CommonService(i0.ɵɵinject(i1.MatSnackBar)); }, token: CommonService, providedIn: "root" });
