<button mat-icon-button class="close-button" mat-dialog-close>
  <mat-icon class="close-icon">close</mat-icon>
</button>
<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content>
  <div>
    <p>{{data.description}}</p>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div>
    <button type="button" class="btn btn-outline-dark waves-effect waves-light min-width-100" mat-dialog-close
    >{{data.buttons[0].name}}</button>
    <button type="button" class="btn action-btn waves-light min-width-100" [mat-dialog-close]="true">{{data.buttons[1].name}}</button>
  </div>
</mat-dialog-actions>