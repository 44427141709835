import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { Breadcrumb } from '../../model/breadcrumb.model';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
 name: string;
 menu: Breadcrumb[] = [];
 breadcrumbList: Breadcrumb[] = [];
 constructor(private _router: Router, private breadcrumbService: BreadcrumbService) {
  this._router.events.subscribe((event:any) => {
    if(event instanceof NavigationEnd) {
      this.menu = this.breadcrumbService.getMenu();  
      this.listenRouting(event);
    }
  });

 }

 ngOnInit() {
}

  listenRouting(router) {
    let routerUrl: string, routerList: Array<any>, target: any;
      routerUrl = router.urlAfterRedirects;
      if (routerUrl && typeof routerUrl === 'string') {
        target = this.menu;
        this.breadcrumbList.length = 0;
        routerList = routerUrl.slice(1).split('/');
        routerList.forEach((router, index) => {
          if(target){
            target = target.find(page => page.path.slice(2) === router);
            if(target){
              this.breadcrumbList.push({
                displayName: target.displayName,
                path: (index === 0) ? target.path : `${this.breadcrumbList[index-1].path}/${target.path.slice(2)}`
              });
              if (index+1 !== routerList.length) {
                target = target.children;
              }
              this.name=this.breadcrumbList[this.breadcrumbList.length-1].displayName;
            } 
          }
        });
      }
  }

  direct_url(path) {    
      this._router.navigate([path]);    
  }

}
