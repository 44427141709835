import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BehaviorSubject } from "rxjs";



@Injectable({
providedIn:'root'
})

export class CommonService {
    public currentAssistant$ = new BehaviorSubject<object>({});
    public currentUser$ = new BehaviorSubject<object>({});
    constructor(private snackBar: MatSnackBar){
        
    }

    openSnacbar(message: string): void {
        this.snackBar.open(message, '', { duration: 5 * 1000 });
    }
}

