import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-create-asset',
  templateUrl: './create-asset.component.html',
  styleUrls: ['./create-asset.component.scss']
})
export class CreateAssetComponent implements OnInit {
  assetFormGroup = this.fb.group({
    id: [0],
    name: ['', Validators.required],
    type: [''],
    url: ['', Validators.required],
    score: ['']
  });
  assetFormInvalid: boolean = false;
  assetMsg: string;
  assetType = [];
  
  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<CreateAssetComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.assetType = data.assetType;
    if (data.formData) {
      this.assetFormGroup.patchValue(data.formData);
    } else {
      this.assetFormGroup.reset();
    }
  }
  ngOnInit() {
  }

  createAsset() {
    if (this.assetFormGroup.invalid) {
      this.assetFormInvalid = true;
    } else {
      this.assetFormInvalid = false;
      const assetDetail = this.getChanges(this.assetFormGroup);
      if (assetDetail) {
        if (this.assetFormGroup.get('id').value) {
          assetDetail['id'] = this.assetFormGroup.get('id').value;
        }
        this.dialogRef.close(assetDetail);
      }
    }
  }

  numberValidation(e) {
    if (e.key.match(/^[0-9,]+$/)) {
      if (e.target.value.toString().indexOf('.') !== -1) {
        const count = e.target.value.toString().split('.')[1].length || 0;
        if (count > 5) {
          return false;
        }
      }
      return true;
    }
    return false;
  }


  checkScore() {
    const type = this.assetFormGroup.get('type').value;
    const score = this.assetFormGroup.get('score').value;
    if (!type && score) {
      this.assetMsg = 'Please select asset type';
      this.assetFormGroup.get('type').setErrors({ incorrect: true });
    }else{
      this.assetMsg = '';
      this.assetFormGroup.get('type').setErrors(null);
    }
    if (type === 'Whitepaper') {
      this.assetFormGroup.get('score').setErrors({ incorrect: true });
      this.assetMsg = 'Score will accept between 5-15';
      if (this.between(score, 5, 15)) {
        this.assetMsg = '';
        this.assetFormGroup.get('score').setErrors(null);
      }
    } else if (type === 'Blogs') {
      this.assetFormGroup.get('score').setErrors({ incorrect: true });
      this.assetMsg = 'Score will accept between 5-10';
      if (this.between(score, 5, 10)) {
        this.assetMsg = '';
        this.assetFormGroup.get('score').setErrors(null);
      }
    } else if (type === 'Demo/Pricing Page Link') {
      this.assetFormGroup.get('score').setErrors({ incorrect: true });
      this.assetMsg = 'Score will accept between 20-30';
      if (this.between(score, 20, 30)) {
        this.assetMsg = '';
        this.assetFormGroup.get('score').setErrors(null);
      }
    } else if (type === 'Video Link') {
      this.assetFormGroup.get('score').setErrors({ incorrect: true });
      this.assetMsg = 'Score will accept between 10-20';
      if (this.between(score, 10, 20)) {
        this.assetMsg = '';
        this.assetFormGroup.get('score').setErrors(null);
      }
    } else if (type === 'Presentation Deck') {
      this.assetFormGroup.get('score').setErrors({ incorrect: true });
      this.assetMsg = 'Score will accept between 15-25';
      if (this.between(score, 15, 25)) {
        this.assetMsg = '';
        this.assetFormGroup.get('score').setErrors(null);
      }
    } else if (type === 'Website Link') {
      this.assetFormGroup.get('score').setErrors({ incorrect: true });
      this.assetMsg = 'Score will accept between 5-10';
      if (this.between(score, 5, 10)) {
        this.assetMsg = '';
        this.assetFormGroup.get('score').setErrors(null);
      }
    }
  }

  between(value, first, last) {
    let lower = Math.min(first, last), upper = Math.max(first, last);
    return value >= lower && value <= upper;
  }

  private getChanges(form): any {
    const updatedValues: any = {};
    this.getUpdates(form, updatedValues);
    return updatedValues;
  }

  private getUpdates(
    formItem: FormGroup | FormArray | FormControl, updatedValues: any, name?: string) {
    if (formItem instanceof FormControl) {
      if (name && formItem.dirty) {
        updatedValues[name] = formItem.value;
      }
    } else {
      for (const formControlName in formItem.controls) {
        if (formItem.controls.hasOwnProperty(formControlName)) {
          const formControl = formItem.controls[formControlName];

          if (formControl instanceof FormControl) {
            this.getUpdates(formControl, updatedValues, formControlName);
          } else if (
            formControl instanceof FormArray &&
            formControl.dirty &&
            formControl.controls.length > 0
          ) {
            updatedValues[formControlName] = [];
            this.getUpdates(formControl, updatedValues[formControlName]);
          } else if (formControl instanceof FormGroup && formControl.dirty) {
            updatedValues[formControlName] = {};
            this.getUpdates(formControl, updatedValues[formControlName]);
          }
        }
      }
    }
  }


}
