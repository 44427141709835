import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { AppRoutesModule } from "./app.routes";
import { CoreModule } from "./core/core.module";
import { DefaultLayoutModule } from "./features/default-layout/default-layout.module";
import { BasicAuthInterceptor } from "./helper/basic-auth.interceptor";
import { ErrorInterceptor } from "./helper/error.interceptor";
import { ApiService } from "./services/api.service";
import { SharedModule } from "./shared/shared.module";

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FlexLayoutModule,
    HttpClientModule,
    AppRoutesModule,
    CoreModule.forRoot(),
    DefaultLayoutModule,
    SharedModule,
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    ApiService,
    { provide: "HOST", useValue: window.location.host },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
})
export class AppModule {}
