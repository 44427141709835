import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
declare var CKEDITOR: any;
@Component({
  selector: 'app-reply-forward',
  templateUrl: './reply-forward.component.html',
  styleUrls: ['./reply-forward.component.scss']
})
export class ReplyForwardComponent implements OnInit {
  editorInstance;
  show_cc: boolean = false;
  emailFormGroup = this.fb.group({
    fromEmail: [''],
    toEmail: ['', Validators.required],
    ccEmailList: [[]],
    subject: [''],
    msgBody: [''],
    assistantId: ['', Validators.required],
    leadId: ['']
  });
  @ViewChild("myckeditor", { static: true }) ckeditor: any;
  emailFormInvalid: boolean;
  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ReplyForwardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.editorInstance = null;
  }

  ngOnInit() {
    this.editorInstance = CKEDITOR.replace(this.ckeditor.nativeElement, {
      toolbarGroups: [
        { name: 'styles' },
        { name: 'basicstyles', groups: ['basicstyles'] }
      ],
      resize_enabled: false,
      removePlugins: 'elementspath,image',
      extraPlugins: 'easyimage,font,justify',
      colorButton_foreStyle: {
        element: 'font',
        attributes: { 'color': '#(color)' }
      },
      height: 188,
      removeDialogTabs: 'image:advanced;link:advanced',
      removeButtons: 'Subscript,Superscript,Source,Anchor,Table,HorizontalRule,SpecialChar,Smiley,PageBreak,Iframe',
      format_tags: 'p;h1;h2;h3;pre;div',
    });
    this.ckEditorSetValue();
    if (this.data.title === 'Reply Email') {
      this.emailFormGroup.get('toEmail').setValue(this.data.mailData.email);
      this.emailFormGroup.get('toEmail').markAsDirty();
    } else {
      this.editorInstance.setData(this.data.mailData.body);
      this.emailFormGroup.get('msgBody').setValue(this.data.mailData.body);
    }
    this.emailFormGroup.get('subject').setValue(this.data.mailData.subject);
    this.emailFormGroup.get('leadId').setValue(this.data.mailData.leadId);
  }

  ckEditorSetValue() {
    const that = this;
    that.editorInstance.on('change', function (ev) {
    that.emailFormGroup.get('msgBody').setValue(ev.editor.getData());
    })
  }
  addCC() {
    this.show_cc = true;
  }

  sendMail() {
    if (this.emailFormGroup.invalid) {
      this.emailFormInvalid = true;
    } else {
      this.emailFormInvalid = false;
      if (this.emailFormGroup.value.ccEmailList.length > 0) {
        this.emailFormGroup.value.ccEmailList = [this.emailFormGroup.value.ccEmailList];
      }
      this.dialogRef.close(this.emailFormGroup.value);
    }
  }
}

