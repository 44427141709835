import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { CommonService } from '../services/common.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService, private commonService: CommonService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                // this.authenticationService.logout();
                this.commonService.openSnacbar('Unauthorized user.');
                // location.reload()
            }
            const error = err.error.message || err.statusText;
            if (err.error.message && err.status === 400) {
                this.commonService.openSnacbar(error);
            } else {
                this.commonService.openSnacbar('Something went wrong try again.');
            }
            return throwError(error);
        }))
    }
}