import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-create-lead',
  templateUrl: './create-lead.component.html',
  styleUrls: ['./create-lead.component.scss']
})
export class CreateLeadComponent implements OnInit {
  leadFormGroup = this.fb.group({
    id: [0],
    firstName: ['', Validators.required],
    lastName: [''],
    email: ['', Validators.required],
    accountId: ['', Validators.required],
    title: [''],
    company: [''],
    phone: [''],
    details: [''],
    leadStatus: [''],
    linkedin: ['']
  });

  listFormGroup = this.fb.group({
    id: [0],
    name: ['', Validators.required],
    type: [''],
    description: ['', Validators.required],
    accessList: [false],
  });
  selectedRecord = [];
  listFormInvalid = false;
  leadFormInvalid = false;
  leadStatusList = [];
  accountList = [];
  listTypeList = [];
  selectedListId = '';
  accountError = false;
  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<CreateLeadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data.id == 'prospect-data-modal') {
      this.leadStatusList = data.leadStatusList;
      this.accountList = data.accountList;
      if (data.formData) {
        this.leadFormGroup.patchValue(data.formData);
      } else {
        this.leadFormGroup.reset();
        this.leadFormGroup.get('leadStatus').setValue('New');
        this.leadFormGroup.get('leadStatus').markAsDirty();
      }
    }

    if (data.id == 'prospect-list-modal') {
      this.listTypeList = data.listType;
      if (data.formData) {
        this.listFormGroup.patchValue(data.formData);
      } else {
        this.listFormGroup.reset();
      }
    }
  }

  ngOnInit() {

  }

  createLead() {
    if (this.leadFormGroup.invalid) {
      this.leadFormInvalid = true;
      if(this.accountList && this.accountList.length === 0){
        this.accountError = true;
      }
    } else {
      this.leadFormInvalid = false;
        this.accountError = false;
      const leadDetail = this.getChanges(this.leadFormGroup);
      if (leadDetail) {
        if (this.leadFormGroup.get('id').value) {
          leadDetail['id'] = this.leadFormGroup.get('id').value;
        }
        this.dialogRef.close(leadDetail);
      }
    }
  }


  createList() {
    if (this.listFormGroup.invalid) {
      this.listFormInvalid = true;
    } else {
      this.listFormInvalid = false;
      const listDetail = this.getChanges(this.listFormGroup);
      if (listDetail) {
        if (this.listFormGroup.get('id').value) {
          listDetail['id'] = this.listFormGroup.get('id').value;
        }
        this.dialogRef.close(listDetail);
      }
    }
  }

  selectList(id) {
    this.selectedListId = id;
  }

  addToList() {
    if (this.selectedListId) {
      this.dialogRef.close(this.selectedListId);
    }
  }

  deleteList() {
    if (this.selectedRecord.length > 0) {
      this.dialogRef.close(this.selectedRecord);
    }
  }

  toggleCheckboxAll(event, data) {
    this.selectedRecord = [];
    const checked = event.target.checked;
    data.forEach(item => {
      item.selected = checked;
      if (checked) {
        this.selectedRecord.push(item.id);
      }
    });
  }


  singleCheckbox(event, data) {
    const checked = event.target.checked;
    data.selected = checked;
    const selectedId = this.selectedRecord.findIndex(item => data.id === item);
    if (checked && selectedId === -1) {
      this.selectedRecord.push(data.id);
    } else {
      this.selectedRecord.splice(selectedId, 1);
    }
  }


  private getChanges(form): any {
    const updatedValues: any = {};
    this.getUpdates(form, updatedValues);
    return updatedValues;
  }

  private getUpdates(
    formItem: FormGroup | FormArray | FormControl,
    updatedValues: any,
    name?: string
  ) {
    if (formItem instanceof FormControl) {
      if (name && formItem.dirty) {
        updatedValues[name] = formItem.value;
      }
    } else {
      for (const formControlName in formItem.controls) {
        if (formItem.controls.hasOwnProperty(formControlName)) {
          const formControl = formItem.controls[formControlName];

          if (formControl instanceof FormControl) {
            this.getUpdates(formControl, updatedValues, formControlName);
          } else if (
            formControl instanceof FormArray &&
            formControl.dirty &&
            formControl.controls.length > 0
          ) {
            updatedValues[formControlName] = [];
            this.getUpdates(formControl, updatedValues[formControlName]);
          } else if (formControl instanceof FormGroup && formControl.dirty) {
            updatedValues[formControlName] = {};
            this.getUpdates(formControl, updatedValues[formControlName]);
          }
        }
      }
    }
  }

}
