import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MatNativeDateModule } from '@angular/material';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { PasswordStrengthBarComponent } from '../features/password-strength-bar/password-strength-bar.component';
import { BreadcrumbComponent } from '../features/breadcrumb/breadcrumb.component';
import { BreadcrumbService } from '../services/breadcrumb.service';
import { RouterModule } from '@angular/router';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ToasterMessageComponent } from '../features/toaster-message/toaster-message.component';
import { ConfirmPopupComponent } from './popups/confirm-popup/confirm-popup.component';
import { CreateLeadComponent } from './popups/create-lead/create-lead.component';
import { CreateAccountComponent } from './popups/create-account/create-account.component';
import { CreateAssetComponent } from './popups/create-asset/create-asset.component';
import { CreateUsersComponent } from './popups/create-users/create-users.component';
import { ReplyForwardComponent } from './popups/reply-forward/reply-forward.component';

const components = [PasswordStrengthBarComponent, BreadcrumbComponent, ToasterMessageComponent, ConfirmPopupComponent, CreateLeadComponent, CreateAccountComponent, CreateAssetComponent, CreateUsersComponent, ReplyForwardComponent];

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  FlexLayoutModule,
  MatButtonModule,
  MatCardModule,
  MatIconModule,
  MatListModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  MatToolbarModule,
  MatTooltipModule,
  MatInputModule,
  MatSidenavModule,
  MatCheckboxModule,
  MatExpansionModule,
  MatStepperModule,
  MatSelectModule,
  MatChipsModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSnackBarModule,
  RouterModule,
  MomentDateModule,
  MatTableModule,
  MatPaginatorModule,
  MatDialogModule,
  MatTooltipModule
];

@NgModule({
  declarations: [...components],
  imports: [
    ...modules
  ],
  exports: [
    ...modules, ...components
  ],
  providers: [BreadcrumbService],
  entryComponents: [ConfirmPopupComponent, CreateLeadComponent, CreateAccountComponent, CreateAssetComponent, CreateUsersComponent, ReplyForwardComponent]
})

export class SharedModule { }