    <!-- ======= Header ======= -->

<!-- Topbar Start -->
<div class="navbar-custom">
  <div class="container-fluid">
      <ul class="list-unstyled topnav-menu float-end mb-0">
          <!-- <li class="dropdown notification-list topbar-dropdown">
              <a class="nav-link dropdown-toggle waves-effect waves-light" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                  <i class="bi bi-bell"></i>
                  <span class="badge bg-danger rounded-circle noti-icon-badge">9</span>
              </a>
              <div class="dropdown-menu dropdown-menu-end dropdown-lg">
                  <div class="noti-scroll" data-simplebar>
                      
                      <a href="javascript:void(0);" class="dropdown-item notify-item active">
                          <div class="notify-icon">
                              <img src="assets/images/user.png" class="img-fluid rounded-circle" alt="" /> </div>
                          <p class="notify-details">Cristina Pride</p>
                          <p class="text-muted mb-0 user-msg">
                              <small>Hi, How are you? What about our next meeting</small>
                          </p>
                      </a>
                      
                      <a href="javascript:void(0);" class="dropdown-item notify-item">
                          <div class="notify-icon bg-primary">
                              <i class="mdi mdi-comment-account-outline"></i>
                          </div>
                          <p class="notify-details">Caleb Flakelar commented on Admin
                              <small class="text-muted">1 min ago</small>
                          </p>
                      </a>
                      
                      <a href="javascript:void(0);" class="dropdown-item notify-item">
                          <div class="notify-icon">
                              <img src="assets/images/user.png" class="img-fluid rounded-circle" alt="" /> </div>
                          <p class="notify-details">Karen Robinson</p>
                          <p class="text-muted mb-0 user-msg">
                              <small>Wow ! this admin looks good and awesome design</small>
                          </p>
                      </a>
                      
                      <a href="javascript:void(0);" class="dropdown-item notify-item">
                          <div class="notify-icon bg-warning">
                              <i class="mdi mdi-account-plus"></i>
                          </div>
                          <p class="notify-details">New user registered.
                              <small class="text-muted">5 hours ago</small>
                          </p>
                      </a>
                      
                      <a href="javascript:void(0);" class="dropdown-item notify-item">
                          <div class="notify-icon bg-info">
                              <i class="mdi mdi-comment-account-outline"></i>
                          </div>
                          <p class="notify-details">Caleb Flakelar commented on Admin
                              <small class="text-muted">4 days ago</small>
                          </p>
                      </a>
                      
                      <a href="javascript:void(0);" class="dropdown-item notify-item">
                          <div class="notify-icon bg-secondary">
                              <i class="mdi mdi-heart"></i>
                          </div>
                          <p class="notify-details">Carlos Crouch liked
                              <b>Admin</b>
                              <small class="text-muted">13 days ago</small>
                          </p>
                      </a>
                  </div>

                
                  <a href="javascript:void(0);" class="dropdown-item text-center text-primary notify-item notify-all">
                          View all
                          <i class="fe-arrow-right"></i>
                      </a>

              </div>
          </li> -->

          <li class="dropdown notification-list topbar-dropdown">
              <a class="nav-link dropdown-toggle nav-user me-0 waves-effect waves-light" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                {{currentUser?.username}}  
                <img src="assets/images/user.png" alt="user-image" class="rounded-circle">
                  <span class="pro-user-name ms-1">
                           <i class="mdi mdi-chevron-down"></i> 
                      </span>
              </a>
              <div class="dropdown-menu dropdown-menu-end profile-dropdown ">
                  
                  <div class="dropdown-header noti-title">
                      <h6 class="text-overflow m-0">Welcome !</h6>
                  </div>

                  
                  <!-- <a href="javascript:void(0);" class="dropdown-item notify-item">
                      <i class="fe-user"></i>
                      <span>My Account</span>
                  </a> -->
<!-- 
                  
                  <a href="javascript:void(0);" class="dropdown-item notify-item">
                      <i class="fe-settings"></i>
                      <span>Settings</span>
                  </a>

                  
                  <a href="javascript:void(0);" class="dropdown-item notify-item">
                      <i class="fe-lock"></i>
                      <span>Lock Screen</span>
                  </a>

                  <div class="dropdown-divider"></div> -->

                  
                  <a (click)="logout()" href="#" class="dropdown-item notify-item">
                      <i class="fe-log-out"></i>
                      <span>Logout</span>
                  </a>

              </div>
          </li>
      </ul>

      <!-- LOGO -->
      <div class="logo-box">
          <a class="logo logo-light text-center">
              <span class="logo-sm">
                      <img src="assets/images/fav.png" alt="" height="22">
                  </span>
              <span class="logo-lg">
                      <img src="assets/images/logo.png" alt="">
                  </span>
          </a>
      </div>

      <ul class="list-unstyled topnav-menu topnav-menu-left m-0">
          <li>
              <button class="button-menu-mobile waves-effect waves-light" (click)="isNavBarOpen = !isNavBarOpen;toggleSideNav()">
                  <i class="bi bi-chevron-double-left" *ngIf="isNavBarOpen"></i>
                  <i class="bi bi-chevron-double-right" *ngIf="!isNavBarOpen"></i>
                  </button>
          </li>

          <li>
              <!-- Mobile menu toggle (Horizontal Layout)-->
              <a class="navbar-toggle nav-link" data-bs-toggle="collapse" data-bs-target="#topnav-menu-content">
                  <div class="lines">
                      <span></span>
                      <span></span>
                      <span></span>
                  </div>
              </a>
              <!-- End mobile menu toggle-->
          </li>

      </ul>
      <div class="clearfix"></div>
  </div>
</div>
<!-- end Topbar -->  <!-- End Header -->

  <!-- ======= Sidebar ======= -->
<div class="left-side-menu">
  <div class="h-100" data-simplebar>
      <!--- Sidemenu -->
      <div id="sidebar-menu">
          <ul id="side-menu">
              <li (click)="setClassNav();setAcive()">
                  <a [routerLinkActive]="['active']" [routerLink]="['/home']" [routerLinkActiveOptions]="{exact: true}" class="side-active">
                      <i class="bi bi-grid"></i>
                      <span> Dashboard </span>
                  </a>
              </li>
                <ng-container *ngIf="(currentUser?.isAdmin && currentUser?.isSuperAdmin); else noRecord">    
                <li (click)="setClassNav();setAcive()">
                <a [routerLinkActive]="['active']" [routerLink]="['/user/user-list']" [routerLinkActiveOptions]="{exact: true}" class="side-active">
                    <i class="bi bi-people-fill"></i>
                    <span> Users </span>
                </a>   
            </li>
            </ng-container>
            <ng-template #noRecord>
              <li (click)="setClassNav();setAcive()">
                  <a [routerLinkActive]="['active']" [routerLink]="['/inbox']" [routerLinkActiveOptions]="{exact: true}" class="side-active">
                      <i class="bi bi-envelope"></i>
                      <span> Inbox </span>
                  </a>
              </li>
              <li>
                  <a href="#sidebarProspect" data-bs-toggle="collapse">
                      <i class="bi bi-people"></i>
                      <span> Prospect </span>
                      <span class="menu-arrow"></span>
                  </a>
                  <div class="collapse" id="sidebarProspect">
                      <ul class="nav-second-level">
                          <li (click)="setClassNav();setAcive()">
                              <a [routerLinkActive]="['active']" [routerLink]="['/prospect/prospect-list']" [routerLinkActiveOptions]="{exact: true}" class="side-active">Prospect List</a>
                          </li>
                          <li (click)="setClassNav();setAcive()">
                              <a [routerLinkActive]="['active']" [routerLink]="['/prospect/prospect-data']" [routerLinkActiveOptions]="{exact: true}" class="side-active">Prospect Data</a>
                          </li>
                      </ul>
                  </div>
              </li>
              <li>
                  <a href="#sidebarCampaigns" data-bs-toggle="collapse">
                      <i class="bi bi-grid-1x2"></i>
                      <span> Campaign Management </span>
                      <span class="menu-arrow"></span>
                  </a>
                  <div class="collapse" id="sidebarCampaigns">
                      <ul class="nav-second-level">
                          <li (click)="setClassNav();setAcive()">
                              <a [routerLinkActive]="['active']" [routerLink]="['/campaign-management/campaigns']" [routerLinkActiveOptions]="{exact: true}" class="side-active">Campaigns</a>
                          </li>
                          <li (click)="setClassNav();setAcive()">
                              <a [routerLinkActive]="['active']" [routerLink]="['/campaign-management/content']" [routerLinkActiveOptions]="{exact: true}" class="side-active">Content</a>
                          </li>
                      </ul>
                  </div>
              </li>
              <li (click)="setClassNav();setAcive()">
                  <a [routerLinkActive]="['active']" [routerLink]="['/abm-accounts']" [routerLinkActiveOptions]="{exact: true}" class="side-active">
                      <i class="bi bi-grid-3x3-gap"></i>
                      <span>ABM Accounts</span>
                  </a>
              </li>
              <!-- <li (click)="setClassNav();setAcive()">
                <a [routerLinkActive]="['active']" [routerLink]="['/reports']" [routerLinkActiveOptions]="{exact: true}" class="side-active">
                    <i class="bi bi-file-bar-graph"></i>
                    <span>Reports</span>
                </a>
            </li> -->
              <li>
                  <a href="#sidebarSettings" data-bs-toggle="collapse">
                      <i class="bi bi-gear"></i>
                      <span> Settings </span>
                      <span class="menu-arrow"></span>
                  </a>
                  <div class="collapse" id="sidebarSettings">
                      <ul class="nav-second-level">
                          <li (click)="setClassNav();setAcive()">
                              <a [routerLinkActive]="['active']" [routerLink]="['/settings/ai-assistant']" [routerLinkActiveOptions]="{exact: true}" class="side-active">AI Assistant</a>
                          </li>
                          <li (click)="setClassNav();setAcive()" *ngIf="currentUser?.isAdmin">
                            <a [routerLinkActive]="['active']" [routerLink]="['/settings/users']" [routerLinkActiveOptions]="{exact: true}" class="side-active">Users</a>
                        </li>
                      </ul>
                  </div>
              </li>
            </ng-template>
        </ul>
      </div>
      <!-- End Sidebar -->
      <div class="clearfix"></div>
  </div>
  <!-- Sidebar -left -->
</div>
<!-- Left Sidebar End -->

  <!-- End Sidebar-->
<section>
  <router-outlet></router-outlet>
</section>
