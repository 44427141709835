import { Routes } from '@angular/router';
const ɵ0 = () => import("./pages/sign-in/sign-in.module.ngfactory").then(m => m.SignInModuleNgFactory), ɵ1 = () => import("./pages/sign-up/sign-up.module.ngfactory").then(m => m.SignUpModuleNgFactory), ɵ2 = () => import("./features/default-layout/default-layout.module.ngfactory").then(m => m.DefaultLayoutModuleNgFactory);
export const appRoutes = [
    {
        path: 'sign-in',
        loadChildren: ɵ0
    },
    {
        path: 'sign-up',
        loadChildren: ɵ1
    },
    {
        path: '',
        loadChildren: ɵ2
    },
    {
        path: '**',
        redirectTo: 'sign-in',
        pathMatch: 'full'
    }
];
export class AppRoutesModule {
}
export { ɵ0, ɵ1, ɵ2 };
